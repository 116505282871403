import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { colors } from "../../../includes/constants/Colors";
import Icon from "../../../../assets/icons/i_icon.svg";

import Details from "./Details";
import RichTextEditor from "./RichTextEditor";

let previousLength = 0;

const ThirdStep = ({
  imageFiles,
  recipeName,
  recipeType,
  cookingTime,
  servings,
  calory,
  ingredients,
  preparation,
  showErrors,
  categories,
  selectedCategories,
  setImageFiles,
  setIngredients,
  setPreparation,
}) => {
  // const [showIngredientsEditor, setShowIngredientsEditor] = useState(false);
  const [showPreparationEditor, setShowPreparationEditor] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleIngredients = (event) => {
    const bullet = "\u2022";
    const newLength = event.target.value.length;
    const characterCode = event.target.value.substr(-1).charCodeAt(0);

    if (newLength > previousLength) {
      if (characterCode === 10) {
        event.target.value = `${event.target.value}${bullet} `;
      } else if (newLength === 1) {
        event.target.value = `${bullet} ${event.target.value}`;
      }
    }
    previousLength = newLength;
  };

  return (
    <MainContainer>
      <Details
        imageFiles={imageFiles}
        recipeName={recipeName}
        recipeType={recipeType}
        cookingTime={cookingTime}
        servings={servings}
        calory={calory}
        categories={categories}
        selectedCategories={selectedCategories}
        setImageFiles={setImageFiles}
        notesShow
      />
      <Bottom>
        <InputField>
          <Label>
            <LabelBox>
              Ingredients
              <Info>
                <img src={Icon} alt="info" />
                <span className="tooltiptext">maximum 15 items</span>
              </Info>
            </LabelBox>
            <span>
              eg: 1/2 cup, sugar <br />
              (press enter to add another)
            </span>
            {showErrors && !ingredients && (
              <span className="error">This field is required</span>
            )}
          </Label>

          <InputBox>
            <Textarea
              placeholder="Write here"
              value={ingredients}
              rows={4}
              onChange={(e) => {
                e.preventDefault();
                setIngredients(e.target.value);
              }}
              onInput={handleIngredients}
            />
          </InputBox>
        </InputField>
        <InputField>
          <Label>
            <LabelBox>
              Preparation
              <Info>
                <img src={Icon} alt="info" />
                <span className="tooltiptext">maximum 500 words</span>
              </Info>
            </LabelBox>
            <span>
              Write paragraph by paragraph, <br />
              press enter to write next paragraphs.
            </span>
            {showErrors && !preparation && (
              <span className="error">This field is required</span>
            )}
          </Label>

          <InputBox onClick={() => setShowPreparationEditor(true)}>
            {showPreparationEditor ? (
              <RichTextEditor value={preparation} onChange={setPreparation} />
            ) : (
              <p className="placeholder">Write here</p>
            )}
          </InputBox>
        </InputField>
      </Bottom>
    </MainContainer>
  );
};

export default ThirdStep;

const MainContainer = styled.div``;
const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
const InputField = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  @media all and (max-width: 630px) {
    flex-direction: column;
  }
`;
const Label = styled.label`
  font-size: 20px;
  font-size: 500;
  font-family: "dm_sansmedium";
  width: 30%;
  span {
    display: block;
    color: ${colors.darkGray};
    font-size: 14px;
    font-weight: 400;
    margin-top: 8px;
    &.error {
      color: ${colors.red};
    }
  }
  @media all and (max-width: 630px) {
    width: 100%;
  }
  @media all and (max-width: 480px) {
    font-size: 14px;
  }
`;
const LabelBox = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
const InputBox = styled.div`
  border: 2px solid ${colors.lightGray};
  border-radius: 8px !important;
  width: 70%;
  height: 148px;
  p {
    &.placeholder {
      color: ${colors.fontColorSecondary};
      font-size: 14px;
      font-family: "dm_sansregular";
      padding: 16px 20px;
    }
  }
  &:has(.ql-editor:focus) {
    border: 2px solid ${colors.primary};
  }
  &:has(textarea:focus) {
    border: 2px solid ${colors.primary};
  }
  @media all and (max-width: 630px) {
    width: 100%;
  }
`;
const Textarea = styled.textarea`
  width: 100%;
  height: 100%;
  resize: none;
  font-size: 14px;
  font-family: "dm_sansmedium";
  color: ${colors.black};
  padding: 16px 20px;
  &::placeholder {
    color: ${colors.fontColorSecondary};
    font-size: 14px;
    font-family: "dm_sansregular";
  }
`;
const Info = styled.div`
  position: relative;
  img {
    display: block;
    width: 24px;
    height: 24px;
  }
  span {
    visibility: hidden;
    width: 120px;
    background-color: ${colors.borderLight};
    color: ${colors.darkGray};
    text-align: center;
    font-size: 10px;
    padding: 5px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: ${colors.lightGray} transparent transparent transparent;
    }
  }
  &:hover {
    span {
      visibility: visible;
      opacity: 1;
    }
  }
`;
