import React from 'react'
import styled from "styled-components";
import { colors } from "../constants/Colors";

const PrimaryChip = ({classname, text, onclick}) => {
  return (
    <Container className={classname} onClick={onclick}>{text}</Container>
  )
}

export default PrimaryChip

const Container = styled.div`
    height: 38px;
    width: max-content;
    border-radius: 78px;
    border: 1px solid ${colors.lightGray};
    padding: 8px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 400;
    cursor: default;
    &.active{
        color: ${colors.text_100};
        background: ${colors.primary};
        border: 1px solid transparent;
    }
`;