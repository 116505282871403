import React from "react";
import styled from "styled-components";

function ComingSoon() {
    return (
        <MainContainer>
            <InnerContainer>
                <Top>
                    <LogoBox>
                        <LogoImg
                            src="https://tegain-project-assets.sgp1.digitaloceanspaces.com/guester/guester-logo.png"
                            alt="Logo"
                        />
                    </LogoBox>
                    <Title>Coming Soon...</Title>
                    <SubTitle>Website Under Construction!</SubTitle>
                </Top>
                <Bottom>
                    <ContactItem>
                        <Icon>
                            <IconImg
                                src="https://tegain-project-assets.sgp1.digitaloceanspaces.com/guester/icons/phone.svg"
                                alt="Phone"
                            />
                        </Icon>
                        <Text>+91 999 888 6666</Text>
                    </ContactItem>
                    <ContactItem>
                        <Icon>
                            <IconImg
                                src="https://tegain-project-assets.sgp1.digitaloceanspaces.com/guester/icons/mail_icon.svg"
                                alt="Mail"
                            />
                        </Icon>
                        <Text>guester@gmail.com</Text>
                    </ContactItem>
                    <ContactItem>
                        <Icon>
                            <IconImg
                                src="https://tegain-project-assets.sgp1.digitaloceanspaces.com/guester/icons/watsapp_icon.svg"
                                alt="Watsapp"
                            />
                        </Icon>
                        <Text>+91 999 888 6667</Text>
                    </ContactItem>
                </Bottom>
                <ImgBox>
                    {/* <Image
                        src="https://tegain-project-assets.sgp1.digitaloceanspaces.com/guester/coming-soon.png"
                        alt="coming-soon"
                    /> */}
                    <Image 
                        src={require("../../assets/images/coming-soon-latest.png")}
                        alt="coming soon"
                    />
                </ImgBox>
            </InnerContainer>
        </MainContainer>
    );
}

const MainContainer = styled.div`
    height: 100vh;
`;
const InnerContainer = styled.div`
    height: 100%;
    background-color: #0b253a;
    width: 75%;
    position: relative;
    padding: 0 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
const Top = styled.div`
    padding: 14% 0;
    height: 92vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
const LogoBox = styled.div`
    width: 200px;
    margin-bottom: 50px;
`;
const LogoImg = styled.img`
    display: block;
    width: 100%;
`;
const Title = styled.h3`
    color: #fff;
    font-weight: bold;
    font-size: 32px;
`;
const SubTitle = styled.p`
    color: #f3f3f3;
    font-size: 16px;
`;
const Bottom = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0;
    @media all and (max-width:860px){
        flex-direction: column;
        gap: 10px;
    }
`;
const ContactItem = styled.div`
    display: flex;
    align-items: center;
`;
const Icon = styled.div`
    width: 16px;
    margin-right: 10px;
`;
const IconImg = styled.img`
    display: block;
    width: 100%;
`;
const Text = styled.div`
    color: #f3f3f3;
    font-size: 16px;
`;
const ImgBox = styled.div`
    width: 50%;
    position: absolute;
    top: 50%;
    right: -21%;
    transform: translateY(-50%);
`;
const Image = styled.img`
    display: block;
    width: 100%;
`;
export default ComingSoon;
