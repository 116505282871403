import React from "react";
import  {useNavigate} from 'react-router-dom';
import styled from "styled-components";
import Clock from "../../../../assets/icons/alarm.svg";
import Eye from "../../../../assets/icons/visibility.svg";
import { colors } from "../../../includes/constants/Colors";

function RecipieCard({userName,recipe,responsive,index,activeRecipe,setActiveRecipe}) {

    const navigate = useNavigate()

    const handleRecipe = () =>{
        navigate("/recipies/single-recipe")
    }

    return (
        <MainContainer 
            onClick={responsive ? ()=>handleRecipe() : ()=>setActiveRecipe(recipe)}
            className={activeRecipe.date_added===recipe.date_added? 'active' : ""}
        >
            <Thumbnail>
                <ThumbImage src={recipe.image} alt={recipe.name} />
                <IconBg>
                    <IconImg
                        src="https://tegain-project-assets.sgp1.digitaloceanspaces.com/guester/icons/favorite-white-outlined.svg"
                        alt="favorite"
                    />
                </IconBg>
            </Thumbnail>
            <Content>
                <TopContent>
                    <Name>
                        <PersonName>{userName}</PersonName>
                        <img src={require("../../../../assets/icons/vertical_ellipsis.svg").default} alt="ellipsis" />
                    </Name>
                    <RecipieName>
                        {recipe.name}
                    </RecipieName>
                </TopContent>
                <BottomContent>
                    <Box>
                        <Icon>
                            <img src={Clock} alt="time" />
                        </Icon>
                        <Text>{recipe.cooking_time}</Text>
                    </Box>
                    <Box>
                        <Icon>
                            <img src={Eye} alt="visibility" />
                        </Icon>
                        <Text>200 views</Text>
                    </Box>
                </BottomContent>
            </Content>
        </MainContainer>
    );
}
const MainContainer = styled.div`
    border-radius: 8px;
    border: 1px solid ${colors.lightGray};
    display: flex;
    gap: 10px;
    padding: 5px;
    cursor: default;
    &.active{
        border: 1px solid ${colors.primary};
    }
`;
const Thumbnail = styled.div`
    width: 140px;
    height: 120px;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
`;
const ThumbImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;
const IconBg = styled.div`
    width: 32px;
    height: 32px;
    padding: 7px 6px 5px 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.32);
    cursor: pointer;
    position: absolute;
    left:14px;
    top: 14px;
    @media all and (max-width: 420px) {
        width: 30px;
        height: 30px;
    }
    @media all and (max-width: 360px) {
        width: 20px;
        height: 20px;
    }
`;
const IconImg = styled.img`
    width: 100%;
    display: block;
`;
const Content = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: column;
    min-height: 120px;
    flex-grow: 1;
    // @media all and (max-width: 1280px){
    //     width: 100%;
    // }
`;
const TopContent = styled.div`
    flex: 1;
`;
const Name = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    img{
        width: 24px;
        height: 24px;
    }
`;
const PersonName = styled.div`
    color: ${colors.fontColorSecondary};
    font-family: "dm_sansregular";
    font-size: 14px;
    margin-bottom: 5px;
`;
const RecipieName = styled.div`
    font-family: "dm_sansmedium";
    font-size: 16px;
    margin-bottom: 5px;
    line-height: 1.2;
`;
const BottomContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
`;
const Box = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
`;
const Icon = styled.div`
    width: 20px;
    height: 20px;
`;
const Text = styled.div`
    font-family: "dm_sansregular";
    font-size: 14px;
`;
export default RecipieCard;
