const Reducer = (state, action) => {
    // let redirectLink = action.redirectLink ? action.redirectLink : "/";
    switch (action.type) {
        case "UPDATE_USER_DETAILS":
            const user_details = {
                ...state.user_details,
                ...action.user_details,
            };
            localStorage.setItem("user_details", JSON.stringify(user_details));
            return {
                ...state,
                user_details: { ...state.user_details, ...action.user_details },
            };
        case "UPDATE_SHOP_DATA":
            return {
                ...state,
                shop_data: { ...state.shop_data, ...action.shop_data },
            };
        case "UPDATE_ERROR_DATA":
            return {
                ...state,
                error_details: {
                    ...state.error_details,
                    ...action.error_details,
                },
            };
        case "UPDATE_LOCATION_DETAILS":
            const location_details = {
                ...state.location_details,
                ...action.location_details,
            };
            localStorage.setItem(
                "location_details",
                JSON.stringify(location_details)
            );
            return {
                ...state,
                location_details: {
                    ...state.location_details,
                    ...action.location_details,
                },
            };
        case "UPDATE_ERROR":
            //  default state of error status
            let isError = action.isError ? action.isError : true;
            let status = 400;
            // if the there is a error message from components then the error message is show else default error message is shown
            let errorMessage = action.errorMessage
                ? action.errorMessage
                : "An error occured, please try later";
            // used to update the redirect link in case of redirect link available

            // if there is a responce in error state
            if (action.error.response) {
                status = action.error.response.status;
            }
            // updates the store
            return {
                ...state,
                errorState: {
                    isError: true,
                    errorData: {
                        status: status,
                        // errorMessage: errorMessage,
                        // redirectLink: redirectLink,
                    },
                },
            };
        case "RESET_ERROR":
            return {
                ...state,
                errorState: {
                    isError: false,
                    errorData: {
                        status: "",
                        // redirectLink: redirectLink,
                    },
                },
            };
        case "HEADER_SEARCH":
            return {
                ...state,
                is_header_search: action.payload,
            };
        default:
            return state;
    }
};

export default Reducer;
