import React from "react";
import styled from "styled-components";


function Others() {
    return (
        <MainContainer>
            <Title>Others</Title>
        </MainContainer>
    )
}

export default Others;

const MainContainer = styled.div`
    padding: 20px;
`;
const Title = styled.h5`
    font-family: "dm_sansmedium";
    font-size: 16px;
    margin-bottom: 10px;
`;