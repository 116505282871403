import React from "react";
import styled from "styled-components";

//icon
import NotFound from "../../../../assets/icons/not-found-icon.svg";

function NoResultsFound() {
    return (
        <MainContainer>
            <Title>
                Search not found
                <IconBox>
                    <IconImg src={NotFound} alt="not-fount-icon" />
                </IconBox>
            </Title>
            <SubTitle>Try using another one,we hope you can find it</SubTitle>
        </MainContainer>
    );
}

const MainContainer = styled.div`
    padding: 10px;
`;
const Title = styled.h4`
    font-family: "dm_sansmedium";
    font-size: 18px;
    margin-bottom: 8px;
`;
const IconBox = styled.span`
    width: 20px;
    display: inline-block;
    margin-left: 10px;
    transform: translateY(3px);
`;
const IconImg = styled.img`
    display: block;
    width: 100%;
`;
const SubTitle = styled.p`
    font-family: "dm_sansregular";
    font-size: 16px;
`;
export default NoResultsFound;
