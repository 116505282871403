import React, { useContext, useState } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";

//constants
import { Context } from "../../../contexts/Store";
import { baseConfig } from "../../../axiosConfig";

// components
import SliderNextArrow from "./SliderNextArrow";
import SliderPrevArrow from "./SliderPrevArrow";
import SocialShare from "../../includes/modal/SocialShare";

function ViewGallerySlider({ data, restaurantID, type, setIsFavPopUp, setFavMessage, shopData }) {
  const navigate = useNavigate();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SliderNextArrow />,
    prevArrow: <SliderPrevArrow />,
  };
  const { state } = useContext(Context);
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isShare, setIsShare] = useState(false);
  const [isFavorite, setFavorite] = useState(data.is_favorite);
  const [error, setError] = useState("");

  const handleFavourites = (e) => {
    e.stopPropagation();
    let access_token = state.user_details.access_token;
    baseConfig
        .post(
            "users/favorite/",
            {
                category: shopData.category && shopData.category,
                pk: shopData.id,
            },
            {
                headers: {
                    Authorization: "Bearer " + access_token,
                },
            }
        )
        .then((response) => {

            let { StatusCode, message } = response.data;
            if (StatusCode === 6000) {
                if (message === "Added To Favorite") {
                    setIsFavPopUp(true);
                    setFavMessage(message);
                    setFavorite((prev) => !prev);
                } else {
                    setIsFavPopUp(true);
                    setFavMessage(message);
                    setFavorite((prev) => !prev);
                    // if (
                    //     location.pathname === "/favourites/restuarants" ||
                    //     location.pathname === "/profile/restuarants"
                    // ) {
                    //     const result = favRestaurants.filter(
                    //         (obj) => obj.id != data.id
                    //     );
                    //     setFavRestaurants(result);
                    // } else {
                    //     setFavorite((prev) => !prev);
                    // }
                }
            } else {
                setError(message);
            }
        })
        .catch((error) => {
            setError("Something went wrong, please try again later.");
        });
};

  return (
    <MainContainer>
      <Slider {...settings}>
        {data.length !== 0 &&
          data?.slice(0, 6).map((item, index) =>
            item.file_type === "10" ? (
              <ImageBox key={index}>
                {index === 0 ? (
                  <Image src={item.file} alt="Gallery-Image" />
                ) : (
                  <Video controls>
                    <source src={item.file} />
                  </Video>
                )}
              </ImageBox>
            ) : (
              item.file_type === "20" && (
                <ImageBox key={index}>
                  <Image src={item.thumbnail} alt="Gallery-Image" />
                </ImageBox>
              )
            )
          )}
      </Slider>
      {type !== "recipe" && (
        <Button
          onClick={() => navigate(`/restuarant/view/${restaurantID}/gallery/`)}
        >
          View Gallery
          <ArrowBox>
            <ArrowImg
              src="https://tegain-project-assets.sgp1.digitaloceanspaces.com/guester/icons/slider-arrow-right.svg"
              alt="RightArrow"
            />
          </ArrowBox>
        </Button>
      )}
      <IconsBox>
        <IconBg onClick={() => setIsShare(!isShare)}>
          <Icon>
            <IconImg
              src="https://tegain-project-assets.sgp1.digitaloceanspaces.com/guester/icons/share.svg"
              alt="Share"
            />
          </Icon>
          {isShare && <SocialShare />}
        </IconBg>
        <IconBg
          onClick={(e) => {
            // e.preventDefault();
            let action = "login";
            state.user_details.is_verified
              ? handleFavourites(e)
              : setSearchParams({ action });
          }}
        >
          {isFavorite ? (
          <Icon>
            <IconImg
              src="https://tegain-project-assets.sgp1.digitaloceanspaces.com/guester/icons/favourite-active.svg"
              alt="favorite"
            />
          </Icon>
          )
          :(
            <Icon>
              <IconImg
                src="https://tegain-project-assets.sgp1.digitaloceanspaces.com/guester/icons/favorite-white-outlined.svg"
                alt="add to favorite"
              />
          </Icon>
          )
          }
        </IconBg>
      </IconsBox>
    </MainContainer>
  );
}
const MainContainer = styled.div`
  border-radius: 12px;
  position: relative;
  /* min-height: 300px; */
  & .slick-slide {
    /* height: 300px; */
    border-radius: 16px;
    overflow: hidden;
  }
  /* & .slick-track {
        height: max-content;
    } */
  & .slick-track {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    margin-bottom: 10px;
  }
  & .slick-slide img {
    object-fit: cover !important;
    height: 100%;
  }
  & .slick-prev {
    left: 30px;
    z-index: 10;
    @media all and (max-width: 360px) {
      left: 10px;
    }
  }
  & .slick-prev:before {
    content: "";
  }
  .slick-next {
    right: 30px;
    z-index: 10;
    @media all and (max-width: 360px) {
      right: 10px;
    }
  }
  .slick-next:before {
    content: "";
  }
  & .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    background-color: rgba(0, 0, 0, 0.32);
  }
  & .slick-dots {
    bottom: 20px;
    @media all and (max-width: 1024px) {
      bottom: 40px;
    }
    @media all and (max-width: 640px) {
      bottom: 50px;
    }
  }
  & .slick-dots li.slick-active {
    width: 40px;
  }
  & .slick-dots li.slick-active button:before {
    color: #fff;
    width: 40px;
  }
  & .slick-dots li button:before {
    background-color: #fff;
    backdrop-filter: 0.5;
    border-radius: 8px;
    width: 10px;
    height: 5px;
    content: "";
  }
`;
const ImageBox = styled.div`
  height: 350px;
  background-color: azure;
  @media all and (max-width: 1080px) {
    height: 300px;
  }
  @media all and (max-width: 640px) {
    height: 250px;
  }
  @media all and (max-width: 360px) {
    height: 230px;
  }
`;
const Image = styled.img`
  display: block;
  width: 100%;
  height: 100% !important;
  object-fit: cover !important;
  border-radius: 12px;
`;
const Video = styled.video`
  display: block;
  width: 100%;
  height: 100% !important;
  object-fit: cover !important;
  border-radius: 12px;
`;
const Button = styled.div`
  border-radius: 8px;
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 10px 20px;
  font-size: 14px;
  font-family: "dm_sansregular";
  cursor: pointer;
  display: flex;
  align-items: center;
  @media all and (max-width: 420px) {
    padding: 5px 10px;
    font-size: 12px;
  }
  @media all and (max-width: 360px) {
    left: 5px;
  }
`;
const ArrowBox = styled.div`
  margin-left: 10px;
  width: 6px;
`;
const ArrowImg = styled.img`
  display: block;
  width: 100%;
`;
const IconsBox = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
`;
const IconBg = styled.div`
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.32);
  margin-right: 10px;
  cursor: pointer;
  position: relative;
  &:last-child {
    margin-right: 0;
  }
  @media all and (max-width: 420px) {
    width: 30px;
    height: 30px;
    margin-right: 5px;
  }
  @media all and (max-width: 360px) {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    &:last-child {
      margin-right: 0;
    }
  }
`;
const Icon = styled.div`
  width: 20px;
  @media all and (max-width: 360px) {
    width: 12px;
    height: 12px;
  }
`;
const IconImg = styled.img`
  width: 100%;
  display: block;
`;
export default ViewGallerySlider;
