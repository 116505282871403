import React, { createContext, useReducer } from "react";
import Reducer from "./Reducer";

const initialState = {
    user_details: {
        is_verified: false,
        phone: "",
        access_token: "",
        userId: "",
        email: "",
    },
    location_details: {
        latitude: "10.3468925",
        longitude: "76.2074124",
        // latitude: "",
        // longitude: "",
        address: "",
    },
    shop_data: {},
    errorState: {
        isError: false,
        errorData: {
            status: 200,
            // redirectLink: "/",
        },
    },
    is_header_search: false,
};

const Store = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer, initialState);

    return (
        <Context.Provider value={{ state, dispatch }}>
            {children}
        </Context.Provider>
    );
};

export const Context = createContext(initialState);
export default Store;
