import React from "react";
import styled from "styled-components";
import { Slider } from "@mui/material";

import { colors } from "../../../includes/constants/Colors";

const RecipeSlider = ({
  title,
  image,
  min,
  max,
  step,
  marks,
  value,
  valueLabelFormat,
  onChange,
}) => {
  return (
    <Content>
      <TitleBox>
        <Title>{title}</Title>
        <ImageBox>
          <img src={image} alt="image" />
        </ImageBox>
      </TitleBox>
      <SliderCustom
        aria-label="Always visible"
        // defaultValue={200}
        // getAriaValueText={valuetext}
        min={min}
        max={max}
        step={step}
        marks={marks}
        value={value}
        valueLabelDisplay="on"
        valueLabelFormat={valueLabelFormat}
        color="secondary"
        onChange={onChange}
      />
    </Content>
  );
};

export default RecipeSlider;

const Content = styled.div`
  padding: 0 12px;
`;
const TitleBox = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 48px;
`;
const Title = styled.h5`
  font-size: 20px;
  font-weight: 500;
`;
const ImageBox = styled.div`
  width: 24px;
  height: 24px;
  img{
    width: 100%;
    height: 100%;
  }
`;
const SliderCustom = styled(Slider)`
    // &.MuiSlider-root {
    //     color: ${colors.primary};
    // }
    .MuiSlider-valueLabel {
        border-radius: 60px;
        background: ${colors.primary};
    }
    .MuiSlider-rail{
      background-color: ${colors.lightGray};
    }
    .MuiSlider-track{
      background-color: ${colors.primary};
      border: 1px solid ${colors.primary};
    }
    .MuiSlider-mark{
      background-color: unset;
    }
    .MuiSlider-thumb{
      background-color: ${colors.primary};
    }
    .MuiSlider-markLabel{
      color: #7A838A;
    }
`;