import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { BsDot } from "react-icons/bs";
import { colors } from "../../../includes/constants/Colors";

function Ingredients({ ingredients }) {

  const [items, setItems] = useState([]);

  //   const handleIngredients = () => {
  //     setItems([]);
  //     // Parse the HTML content of the Rich Text
  //     const parser = new DOMParser();
  //     const parsedHtml = parser.parseFromString(ingredients, "text/html");

  //     // Get an array of paragraph elements
  //     let paragraphs = parsedHtml.querySelectorAll("p");
  //     if(paragraphs.length === 0){
  //         paragraphs = parsedHtml.querySelectorAll("li");
  //     }
  //     console.log("Paragraphs:", paragraphs);
  //     Array.from(paragraphs).map((paragraph, index) => {
  //       // check whether the item is empty with <br> tags or spaces
  //       if (paragraph.innerHTML !== "<br>" && !(/^\s+$/.test(paragraph.innerHTML)))
  //         setItems((prev) => [...prev, paragraph.innerHTML]);
  //     });
  //   };

  const handleIngredients = () => {
    setItems([]);
    let listItems = ingredients
      ?.split(/•|\u2022/)
      .filter((item) => item.trim() !== "");
    // listItems?.map((list) => setItems((prev) => [...prev, list]));
    setItems(listItems)
  };

  useEffect(() => {
    handleIngredients();
  }, [ingredients]);

  return (
    <MainContainer>
      <Title>{items?.length} Ingredients</Title>
      {/* <IngredientsBox>
                <IngredientItem>
                    <BsDot />1 tbsp Ginger
                </IngredientItem>
                <IngredientItem>
                    <BsDot />1 tbsp Ginger
                </IngredientItem>
                <IngredientItem>
                    <BsDot />
                    1/2 pear , small
                </IngredientItem>
                <IngredientItem>
                    <BsDot />1 tbsp Ginger
                </IngredientItem>
                <IngredientItem>
                    <BsDot />1 tbsp Ginger
                </IngredientItem>
                <IngredientItem>
                    <BsDot />1 lbs rib eye steak boneless
                </IngredientItem>
                <IngredientItem>
                    <BsDot />1 tbsp Ginger
                </IngredientItem>
                <IngredientItem>
                    <BsDot />1 tbsp Ginger
                </IngredientItem>
                <IngredientItem>
                    <BsDot />1 tbsp Ginger
                </IngredientItem>
                <IngredientItem>
                    <BsDot />
                    1/2 pear , small
                </IngredientItem>
                <IngredientItem>
                    <BsDot />
                    1/2 pear , small
                </IngredientItem>
        </IngredientsBox> */}
      <IngredientsBox>
        {items?.map((item, index) => (
          <IngredientItem key={index}>
            <BsDot />
            {item}
          </IngredientItem>
        ))}
      </IngredientsBox>
    </MainContainer>
  );
}
const MainContainer = styled.div`
  padding: 20px;
`;
const Title = styled.h5`
  font-family: "dm_sansmedium";
  font-size: 16px;
  margin-bottom: 10px;
`;
const IngredientsBox = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;
  font-family: "dm_sansregular";
  font-size: 16px;
  color: ${colors.black};
  ul {
    list-style: disc;
    li {
      font-family: "dm_sansregular";
      font-size: 16px;
      color: ${colors.black};
    }
  }
  p {
    display: list-item;
    list-style: disc;
    font-family: "dm_sansregular";
    font-size: 16px;
    color: ${colors.black};
  }
  @media all and (max-width: 510px) {
    grid-template-columns: 1fr;
  }
`;
const IngredientItem = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  font-family: "dm_sansregular";
  font-size: 16px;
`;

export default Ingredients;
