import React from "react";
import styled from "styled-components";

// constants
import loader from "../../assets/lotties/loader.gif";

export default function Loader() {
    return (
        <Container>
            <LoaderComponent src={loader} alt="loading..." />
        </Container>
    );
}
const Container = styled.div`
    position: absolute;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
`;

const LoaderComponent = styled.img`
    width: 500px;
    height: 300px;
    @media (max-width: 768px) {
        width: 400px;
    }
    @media (max-width: 480px) {
        width: 350px;
        height: 250px;
    }
`;
