import React from "react";
import { useContext } from "react";
import styled from "styled-components";
import { Context } from "../../../contexts/Store";
import { useNavigate } from "react-router-dom";

// constants
import { colors } from "../constants/Colors";

// components
import Button from "../Button";
import Header from "../Header";

function MainErrorPage({ error }) {
    const {
        state: { errorState },
        dispatch,
    } = useContext(Context);
    const navigate = useNavigate();

    return (
        <Container>
            <TopContainer>
                <Header error={true} />
            </TopContainer>
            {errorState.errorData.status === 400 ? (
                <MainContainer>
                    <ImageContainer>
                        <ErrorImage src="https://tegain-project-assets.sgp1.digitaloceanspaces.com/guester/error-400.png" />
                    </ImageContainer>
                    <TextContainer>
                        <Title>Bad Request</Title>
                        <Description>
                            We couldn't render your notebook
                        </Description>
                    </TextContainer>
                    <BtnContainer>
                        <Button
                            title={"Back to Home"}
                            borderColor={colors.primary}
                            onClick={() => {
                                dispatch({ type: "RESET_ERROR" });
                                navigate("/");
                            }}
                        />
                    </BtnContainer>
                </MainContainer>
            ) : errorState.errorData.status === 403 ? (
                <MainContainer>
                    <ImageContainer>
                        <ErrorImage src="https://tegain-project-assets.sgp1.digitaloceanspaces.com/guester/error-403.png" />
                    </ImageContainer>
                    <TextContainer>
                        <Title>Forbidden</Title>
                        <Description>
                            You don't have permission to access / on this server
                        </Description>
                    </TextContainer>
                    <BtnContainer>
                        <Button
                            title={"Back to Home"}
                            borderColor={colors.primary}
                            onClick={() => {
                                dispatch({ type: "RESET_ERROR" });
                                navigate("/");
                            }}
                        />
                    </BtnContainer>
                </MainContainer>
            ) : errorState.errorData.status === 500 ? (
                <MainContainer>
                    <ImageContainer>
                        <ErrorImage src="https://tegain-project-assets.sgp1.digitaloceanspaces.com/guester/error-500.png" />
                    </ImageContainer>
                    <TextContainer>
                        <Title>Internal Server Error</Title>
                        <Description>This browser is not supported</Description>
                    </TextContainer>
                    <BtnContainer>
                        <Button
                            title={"Back to Home"}
                            borderColor={colors.primary}
                            onClick={() => {
                                dispatch({ type: "RESET_ERROR" });
                                navigate("/");
                            }}
                        />
                    </BtnContainer>
                </MainContainer>
            ) : errorState.errorData.status === 404 || error ? (
                <MainContainer>
                    <ImageContainer>
                        <ErrorImage src="https://tegain-project-assets.sgp1.digitaloceanspaces.com/guester/error-404.png" />
                    </ImageContainer>
                    <TextContainer>
                        <Title>Oops! No page found</Title>
                        <Description>
                            This page doesn’t exist or was removed!
                            <br />
                            We suggest you back to home
                        </Description>
                    </TextContainer>
                    <BtnContainer>
                        <Button
                            title={"Back to Home"}
                            borderColor={colors.primary}
                            onClick={() => {
                                dispatch({ type: "RESET_ERROR" });
                                navigate("/");
                            }}
                        />
                    </BtnContainer>
                </MainContainer>
            ) : null}
        </Container>
    );
}
const Container = styled.div`
    height: 100vh;
    background: #e5e8f1;
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 2000;
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
`;
const TopContainer = styled.div`
    width: 100%;
    background: #fff;
`;
const MainContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const ImageContainer = styled.div`
    width: 35%;
    margin: 0 auto 40px;
    @media all and (max-width: 900px) {
        width: 38%;
    }
    @media all and (max-width: 640px) {
        width: 60%;
    }
`;
const ErrorImage = styled.img`
    display: block;
    width: 100%;
`;
const TextContainer = styled.div``;
const Title = styled.h3`
    font-family: "dm_sansmedium";
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    @media all and (max-width: 900px) {
        font-size: 22px;
        margin-bottom: 15px;
    }
    @media all and (max-width: 480px) {
        font-size: 20px;
    }
`;
const Description = styled.p`
    text-align: center;
    font-size: 16px;
    margin-bottom: 20px;
    @media all and (max-width: 900px) {
        margin-bottom: 15px;
    }
    @media all and (max-width: 480px) {
        font-size: 15px;
    }
`;
const BtnContainer = styled.div`
    margin: 0 auto;
    width: fit-content;
`;

export default MainErrorPage;
