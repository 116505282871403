import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {v4 as uuidv4} from 'uuid';

import { colors } from "../../../includes/constants/Colors";
import PrimaryChip from "../../../includes/chips/PrimaryChip";

import Icon from "../../../../assets/icons/i_icon.svg";
import ImageUpload from "./ImageUpload";
import VideoUpload from "./VideoUpload";

const FirstStep = ({
  selectedCategories,
  setSelectedCategories,
  setImageFiles,
  setVideoFiles,
  setRecipeName,
  setRecipeType,
  setCategories,
  recipeName,
  recipeType,
  imageFiles,
  videoFiles,
  categories,
  showErrors,
}) => {
  const [otherCategory, setOtherCategory] = useState("");

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  const handleRecipeType = (event, type) => {
    event.preventDefault();
    setRecipeType(type);
  };

  const handleCategory = (id) => {
    if (selectedCategories.includes(id)) {
      setSelectedCategories((prev) => prev.filter((item) => item !== id));
    } else {
      if (selectedCategories.length < 6) {
        setSelectedCategories((prev) => [...prev, id]);
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      let found = false
      categories.forEach((category)=>{   
        if((category.name).toUpperCase() === otherCategory.toUpperCase()){
          found = true;
        }
        })
        if(!found){
          setCategories((prev) => [
            ...prev,
            // { id: categories[categories.length - 1].id + 1, name: otherCategory },
            { id: uuidv4(), name: otherCategory },
          ]);
        }
      setOtherCategory("");
    }
  };

  return (
    <>
      <InputField>
        <Label>
          Recipe Name*
          {(showErrors && !recipeName) && (
            <span className="error">This field is required</span>
          )}
        </Label>
        <InputBox>
          <Input
            type="text"
            placeholder="Recipe Name"
            value={recipeName}
            onChange={(e) => {
              e.preventDefault();
              setRecipeName(e.target.value);
            }}
          />
          <Info>
            <img src={Icon} alt="info" />
            <span className="tooltiptext">Only characters</span>
          </Info>
        </InputBox>
      </InputField>
      <InputField>
        <Label>
          Recipe Type*
          {showErrors && recipeType===0 && (
            <span className="error">This field is required</span>
          )}
        </Label>
        <InputButtons>
          <Button
            className={recipeType === 20 ? "active" : ""}
            onClick={(event) => handleRecipeType(event, 20)}
          >
            <Text>Nonveg</Text>
            <CategoryImage>
              <img
                src={require("../../../../assets/icons/non-veg.svg").default}
                alt="Nonveg"
              />
            </CategoryImage>
          </Button>
          <Button
            className={recipeType === 10 ? "active" : ""}
            onClick={(event) => handleRecipeType(event, 10)}
          >
            <Text>Veg</Text>
            <CategoryImage>
              <img
                src={require("../../../../assets/icons/veg.svg").default}
                alt="Veg"
              />
            </CategoryImage>
          </Button>
        </InputButtons>
      </InputField>
      <InputField>
        <Label>
          Add Recipe Images*
            {(showErrors &&
            imageFiles?.length === 0) && (
              <span className="error">This field is required</span>
            )}
        </Label>
        <FileContainer>
          <ImageUpload imageFiles={imageFiles} setImageFiles={setImageFiles} />
          <VideoUpload videoFiles={videoFiles} setVideoFiles={setVideoFiles} />
        </FileContainer>
      </InputField>
      <InputField>
        <Label>
          Recipe Category
          <span>You can add upto 6 categories</span>
          {showErrors && selectedCategories.length === 0 && (
            <span className="error">This field is required</span>
          )}
        </Label>
        <OptionsContainer>
          <Options>
            {categories.map((category) => (
              <PrimaryChip
                key={category.id}
                onclick={() => handleCategory(category.id)}
                classname={
                  selectedCategories.includes(category.id) ? "active" : ""
                }
                text={category.name}
              />
            ))}
          </Options>
          <InputBox className="category">
            <Input
              type="text"
              placeholder="Other"
              value={otherCategory}
              onChange={(e) => setOtherCategory(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </InputBox>
        </OptionsContainer>
      </InputField>
    </>
  );
};

export default FirstStep;

const InputField = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 50px;
`;
const Label = styled.label`
  font-size: 16px;
  font-family: "dm_sansmedium";
  width: 30%;
  span {
    display: block;
    color: ${colors.darkGray};
    font-size: 14px;
    font-weight: 400;
    margin-top: 8px;
    &.error {
      color: ${colors.red};
    }
  }
  @media all and (max-width: 480px){
    font-size: 14px;
  }
`;
const InputBox = styled.div`
  border: 1px solid ${colors.lightGray};
  border-radius: 8px !important;
  width: 70%;
  display: flex;
  align-items: center;
  padding: 12px;
  gap: 12px;
  &.category {
    width: 100%;
  }
`;
const Input = styled.input`
  font-size: 14px;
  font-family: "dm_sansmedium";
  color: ${colors.black};
  flex-grow: 1;
  &::placeholder {
    color: ${colors.fontColorSecondary};
    font-size: 14px;
    font-family: "dm_sansregular";
  }
`;
const Info = styled.div`
  position: relative;
  img{
    display: block;
    width: 24px;
    height: 24px;
  }
  span{
    visibility: hidden;
    width: 120px;
    background-color: ${colors.borderLight};
    color: ${colors.darkGray};
    text-align: center;
    font-size: 10px;
    padding: 5px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
    &::after{
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: ${colors.lightGray} transparent transparent transparent;
    }
  }
  &:hover{
    span{
      visibility: visible;
      opacity: 1;
    }
  }
`;
const InputButtons = styled.div`
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
`;
const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52px;
  border-radius: 8px;
  padding: 16px;
  gap: 16px;
  background-color: ${colors.borderLight};
  flex-grow: 1;
  border: 2px solid transparent;
  &.active {
    background-color: ${colors.secondary};
    border: 2px solid ${colors.primary};
  }
`;
const Text = styled.h6`
  font-size: 14px;
  font-weight: 500;
  color: ${colors.black};
`;
const CategoryImage = styled.div`
  width: 20px;
  height: 20px;
  img {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

const FileContainer = styled.div`
  width: 70%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media all and (max-width: 630px){
    flex-direction: column;
    gap: 20px;
  }
`;
const OptionsContainer = styled.div`
  width: 70%;
`;
const Options = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 8px;
  margin-bottom: 24px;
`;
