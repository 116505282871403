import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";

import { colors } from "../../../includes/constants/Colors";
import Alarm from "../../../../assets/images/recipies/alarm.svg";
import Cooking from "../../../../assets/images/recipies/cooking.svg";
import Fire from "../../../../assets/images/recipies/fire.svg";

import RecipeSlider from "./RecipeSlider";
import Details from "./Details";

const SecondStep = ({
  imageFiles,
  recipeName,
  recipeType,
  cookingTime,
  servings,
  calory,
  categories,
  selectedCategories,
  setImageFiles,
  setCookingTime,
  setServings,
  setCalory
}) => {

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  const timeMarks = [
    {
      value: 5,
      label: "5 min",
    },
    {
      value: 600,
      label: "10 hr",
    },
  ];

  const servingMarks = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 200,
      label: "200",
    },
  ];

  const caloryMarks = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 2000,
      label: "2000",
    },
  ];

  const handleLabelFormat = (value) =>{
    if(value < 60){
      return <div>{`${value} min`}</div>
    }else{
      const hour = Math.floor(value/60)
      const minutes = value % 60
      if(minutes === 0)
        return <div>{`${hour} hr`}</div>
      else
        return <div>{`${hour} hr ${minutes} min`}</div>
    }
  }

  const handleSliderChange = (value,type) => {
    if(type === "time")
        setCookingTime(value);
    else if(type === "servings")
      setServings(value);
    else
      setCalory(value)
    }

  return (
    <MainContainer>
      <Details 
        imageFiles = {imageFiles}
        recipeName = {recipeName}
        recipeType = {recipeType}
        categories = {categories}
        selectedCategories = {selectedCategories}
        setImageFiles = {setImageFiles}
      />
      <Bottom>
        <RecipeSlider 
          title = "Cooking Time"
          image = {Alarm}
          min = {5}
          max = {600}
          step = {10}
          marks = {timeMarks}
          value={cookingTime}
          valueLabelFormat={value=>handleLabelFormat(value)}
          onChange={(e)=>handleSliderChange(e.target.value,"time")}
        />
        <RecipeSlider 
          title = "No of people serving for"
          image = {Cooking}
          min = {0}
          max = {200}
          step = {1}
          marks = {servingMarks}
          value={servings}
          onChange={(e)=>handleSliderChange(e.target.value,"servings")}
        />
        <RecipeSlider 
          title = "Calorific Value"
          image = {Fire}
          min = {0}
          max = {2000}
          step = {10}
          marks = {caloryMarks}
          value={calory}
          onChange={(e)=>handleSliderChange(e.target.value,"calory")}
        />       
      </Bottom>
    </MainContainer>
  );
};

export default SecondStep;

const MainContainer = styled.div``;
const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
`;
