import React, { useContext, useState, useEffect, Suspense } from "react";

// contants
import { Context } from "./../../contexts/Store";

// components
import AppRouter from "./AppRouter";
import Loader from "../../components/includes/Loader";

export default function MainRouter() {
    const [isLoading, setLoading] = useState(true);
    const { dispatch } = useContext(Context);

    async function fetchUserData() {
        let user_details = localStorage.getItem("user_details");
        if (!user_details) {
            localStorage.setItem("user_details", JSON.stringify(user_details));
            user_details = localStorage.getItem("user_details");
        }
        let user_details_stored = JSON.parse(user_details);
        dispatch({
            type: "UPDATE_USER_DETAILS",
            user_details: user_details_stored,
        });
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }
    async function fetchLocationData() {
        let location_details = localStorage.getItem("location_details");
        if (!location_details) {
            localStorage.setItem(
                "location_details",
                JSON.stringify(location_details)
            );
            location_details = localStorage.getItem("location_details");
        }
        let location_details_stored = JSON.parse(location_details);
        dispatch({
            type: "UPDATE_LOCATION_DETAILS",
            location_details: location_details_stored,
        });
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }

    useEffect(() => {
        fetchUserData();
        // fetchLocationData();
    }, []);

    return isLoading ? null : (
        <Suspense fallback={<Loader />}>
            <AppRouter />
        </Suspense>
    );
}
