import React from "react";
import styled from "styled-components";

function Preparations({preparation}) {
    return (
        <MainContainer>
            <Title>Preparation</Title>
            <PreparationDetails dangerouslySetInnerHTML={{__html:preparation}}>
                {/* <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt Lorem ipsum dolor sit amet,
                    consectetur adipiscing
                </p>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate
                </p> */}
            </PreparationDetails>
        </MainContainer>
    );
}
const MainContainer = styled.div`
    padding: 20px;
`;
const Title = styled.h5`
    font-family: "dm_sansmedium";
    font-size: 16px;
    margin-bottom: 10px;
`;
const PreparationDetails = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;
export default Preparations;
