import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import { useContext } from "react";
import { Context } from "../../contexts/Store";
import Loader from "../../components/includes/Loader";
import RecipiesRouter from "./RecipiesRouter";

//components
const RestaurentSinglePage = React.lazy(() =>
    import(
        "../../components/screens/restuarant-single-page/RestaurentSinglePage"
    )
);
const ExploreFood = lazy(() =>
    import("../../components/screens/explore-food/ExploreFood")
);
const ComingSoon = lazy(() => import("../../components/includes/ComingSoon"));
const Home = lazy(() => import("../../components/screens/home/Home"));
const FavouritesRouter = lazy(() => import("./FavouritesRouter"));
const Index = lazy(() => import("../../components/screens/Index"));
const ProfileRouter = lazy(() => import("./ProfileRouter"));
const OffersMainPage = lazy(() =>
    import("../../components/screens/offers/OffersMainPage")
);
const PrivacyPolicy = lazy(() =>
    import("../../components/screens/PrivacyPolicy")
);
const MainErrorPage = lazy(() =>
    import("../../components/includes/error-pages/MainErrorPage")
);
const About = lazy(() => import("../../components/screens/about/About"));
const Community = lazy(() =>
    import("../../components/screens/community/Community")
);
const ContactUs = lazy(() =>
    import("../../components/screens/contact-us/ContactUs")
);
// const RecipiesPage = lazy(() =>
//     import("../../components/screens/recipies/RecipiesPage")
// );

export default function AppRouter() {
    const {
        state: { errorState },
    } = useContext(Context);
    return (
        <>
            {errorState.isError ? (
                <MainErrorPage />
            ) :
            (
                <Suspense fallback={<Loader />}>
                    <Routes>
                        <Route path="/" element={<Index />}>
                            <Route end path="/" element={<Home />} />
                            <Route
                                path="/explore-food"
                                element={<ExploreFood />}
                            />
                            <Route path="/community" element={<Community />} />
                            <Route path="/contact-us" element={<ContactUs />} />
                            <Route
                                path="/offers"
                                element={<OffersMainPage />}
                            />
                            <Route
                                path="recipies/*"
                                element={<RecipiesRouter />}
                            />
                            <Route
                                path="/favourites/*"
                                element={<FavouritesRouter />}
                            />
                            <Route
                                path="/profile/*"
                                element={<ProfileRouter />}
                            />
                            <Route
                                path="/restuarant/view/:restaurantID/*"
                                element={<RestaurentSinglePage />}
                            />
                            {/* <Route path="/restuarant/*" element={<SinglePageRouter />} /> */}
                            <Route
                                path="/privacy-policy"
                                element={<PrivacyPolicy />}
                            />
                            <Route path="/about" element={<About />} />
                        </Route>
                        <Route path="/coming-soon" element={<ComingSoon />} />
                        <Route
                            path="*"
                            element={<MainErrorPage error={404} />}
                        />
                    </Routes>
                </Suspense>
            )}
        </>
    );
}
