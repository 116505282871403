import React from 'react'
import styled from "styled-components";


//rich text editor
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const RichTextEditor = ({value,onChange}) => {

    var toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],
      
        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction
      
        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      
        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],
      
        ['clean']                                         // remove formatting button
      ];
  
      const modules = {
        toolbar: toolbarOptions    // Snow includes toolbar by default
      }

  return (
    <Container>
        <ReactQuill 
            modules = {modules}
            theme="snow" 
            value={value} 
            onChange={onChange} 
        /> 
    </Container>
  )
}

export default RichTextEditor

const Container = styled.div`
  .ql-editor{
    height: 80px !important;
    overflow: scroll !important;
  }
  .ql-container{
    border: none !important;
  }
  .ql-toolbar{
    border: none !important;
    border-bottom: 1px solid #ccc !important;
  }
  .ql-editor strong {
    font-weight: bold;
  }
  .ql-editor em {
    font-style: italic;
  }
  .ql-editor u{
    text-decoration: underline;
  }
`