import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AddRecipies from "../../components/screens/recipies/AddRecipies";
import RecipiesPage from "../../components/screens/recipies/RecipiesPage";
import MainErrorPage from "../../components/includes/error-pages/MainErrorPage";
import Recipies from "../../components/screens/recipies/Recipies";
import SingleRecipe from "../../components/screens/recipies/SingleRecipe";

function RecipiesRouter() {
    return (
        <Routes>
            <Route path="/" element={<Recipies />}>
                <Route path="/" element={<RecipiesPage />} />
                <Route path="/add-recipies" element={<AddRecipies />} />
                <Route path="/single-recipe" element={<SingleRecipe />} />
                <Route path="*" element={<MainErrorPage error={404} />} />
            </Route>
        </Routes>
    );
}

export default RecipiesRouter;
