import React from "react";
import styled from "styled-components";

function SliderNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <ArrowOuterBox className={className} onClick={onClick}>
            <ArrowBox className="classes-slider-arrow">
                <ArrowImg
                    src="https://tegain-project-assets.sgp1.digitaloceanspaces.com/guester/icons/slider-arrow-right.svg"
                    alt="next-arrow"
                />
            </ArrowBox>
        </ArrowOuterBox>
    );
}
const ArrowOuterBox = styled.div`
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.32);
    z-index: 1000;
`;
const ArrowBox = styled.div`
    width: 8px;
`;
const ArrowImg = styled.img`
    display: block;
    width: 100%;
`;

export default SliderNextArrow;
