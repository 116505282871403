import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

// constants
import { colors } from "../constants/Colors";
function RecipieEmptyPage() {
    const navigate = useNavigate();
    return (
        <Container>
            <ImageContainer>
                <EmptyImage src="https://tegain-project-assets.sgp1.digitaloceanspaces.com/guester/location-empty.png" />
            </ImageContainer>
            <Description>You Haven't added any recipies</Description>
            <Browse
                onClick={() => {
                    navigate("/recipies/add-recipies");
                }}
            >
                Add Recipies
            </Browse>
        </Container>
    );
}
const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px 0;
`;
const ImageContainer = styled.div`
    width: 80px;
    margin: 0 auto 30px;
`;
const EmptyImage = styled.img`
    width: 100%;
    display: block;
`;
const Description = styled.p`
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.25px;
    color: ${colors.fontColorPrimary};
    width: 70%;
    margin: 0 auto 20px;
`;
const Browse = styled.p`
    cursor: pointer;
    font-size: 12px;
    font-family: "dm_sansmedium";
    line-height: 20px;
    /* border-bottom: 1px solid ${colors.primary}; */
    width: max-content;
    color: ${colors.primary};
    position: relative;
    &::after {
        content: "";
        height: 1px;
        width: 100%;
        position: absolute;
        bottom: 1px;
        left: 0;
        background-color: ${colors.primary};
    }
`;

export default RecipieEmptyPage;
