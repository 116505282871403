import React, { useCallback } from "react";
import styled from "styled-components";

import { useDropzone } from "react-dropzone";
import { colors } from "../../../includes/constants/Colors";
import Video from "../../../../assets/icons/video-add.svg";
import Close from "../../../../assets/icons/close-icon.svg";

const VideoUpload = ({ videoFiles,setVideoFiles }) => {
  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles?.length) {
      setVideoFiles((previousFiles) => [
        // ...previousFiles,
        ...acceptedFiles.map((file) =>
          Object.assign(file, { preview: URL.createObjectURL(file) })
        ),
      ]);
    }
  }, []);

  const { getRootProps, acceptedFiles, getInputProps } = useDropzone({
    accept: {
      "video/mp4": [".mp4"],
      "video/mkv": [".mkv"],
    },
    maxFiles: 1,
    onDrop
  });

  return videoFiles.length > 0 ?(
    <FileBox>
      <CloseButton
        onClick={(e) => {
          e.preventDefault();
          setVideoFiles([]);
        }}
      >
        <img src={Close} alt="close" />
      </CloseButton>
        <VideoFile  controls="controls">
          <source src={videoFiles[0].preview} type={videoFiles[0].type} />
        </VideoFile>
    </FileBox>
  ) :(
    <FileBox {...getRootProps({ className: "dropzone" })}>
      <FileImage src={Video} alt="video" />
      <Head>Drag and Drop your Videos</Head>
      <SubHead>Files Supported mp4, mkv</SubHead>
      <Or>OR</Or>
      <input {...getInputProps} type="file" />
      <label>Browse Files</label>
    </FileBox>
  );
};

export default VideoUpload;

const FileBox = styled.div`
  position: relative;
  height: 234px;
  width: 48%;
  padding: 10px;
  background: ${colors.lightGreen};
  border-radius: 10px;
  border: 1px dashed ${colors.primary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: default;
  input {
    display: none;
  }
  label {
    font-size: 16px;
    font-weight: 500;
    color: ${colors.primary};
    text-decoration: underline;
    margin-bottom: 10px;
  }
  @media all and (max-width: 630px){
    width: 100%;
  }
`;
const CloseButton = styled.button`
  position: absolute;
  width: 30px;
  height: 30px;
  right: 10px;
  top: 10px;
  border: 1px solid ${colors.lightGray};
  background: ${colors.lightGray};
  border-radius: 50%;
  padding: 5px;
  img {
    display: block;
    width: 100%;
    height: 100%;
  }
`;
const VideoFile = styled.video`
  display: block;
  width: 100%;
  height: calc(100% - 60px);
`;
const FileImage = styled.img`
  width: 36px;
  height: 36px;
  margin-bottom: 24px;
`;
const Head = styled.h4`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 6px;
`;
const SubHead = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: ${colors.darkGray};
  margin-bottom: 12px;
`;
const Or = styled.h6`
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 400;
`;
