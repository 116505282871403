import React, { useCallback } from 'react'
import styled from "styled-components";
import { useDropzone } from "react-dropzone";

import { colors } from "../../../includes/constants/Colors";
import Edit from "../../../../assets/images/recipies/edit-bg.svg";
import Alarm from "../../../../assets/images/recipies/alarm.svg";
import Cooking from "../../../../assets/images/recipies/cooking.svg";
import Fire from "../../../../assets/images/recipies/fire.svg";


const Details = ({
  imageFiles,
  recipeName,
  recipeType,
  cookingTime,
  servings,
  calory,
  categories,
  selectedCategories,
  setImageFiles,
  notesShow
}) => {
    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles?.length) {
          setImageFiles((previousFiles) => [
            // ...previousFiles,
            ...acceptedFiles.map((file) =>
              Object.assign(file, { preview: URL.createObjectURL(file) })
            ),
          ]);
        }
      }, []);
  
      const { getRootProps, acceptedFiles, getInputProps } = useDropzone({
        accept: {
          "image/jpg": [".jpg"],
          "image/png": [".png"],
        },
        maxFiles: 1,
        onDrop,
      });

      const handleTime = () =>{
        if(cookingTime < 60){
          return <div>{`${cookingTime} min`}</div>
        }else{
          const hour = Math.floor(cookingTime/60)
          const minutes = cookingTime % 60
          if(minutes === 0)
            return <div>{`${hour} hr`}</div>
          else
            return <div>{`${hour} hr ${minutes} min`}</div>
        }
      }

  return (
    <Top>
        <Left className='left' {...getRootProps({ className: "dropzone" })}>
        {imageFiles.length >0 &&
          <>
            <img 
              className="edit" 
              src={Edit} 
              alt="edit"
            />
            <img src={imageFiles[0].preview} alt="image" />
            <input {...getInputProps} onChange={(e) => e.preventDefault()} />
          </>
        }
        </Left>
        <Right>
          <Head>
            <Name>{recipeName}</Name>
            <Type>
            { recipeType === 20 ?
                <img src={require("../../../../assets/icons/non-veg.svg").default} alt="non veg" />
              :
              <img src={require("../../../../assets/icons/veg.svg").default} alt="veg" />
            }
            </Type>
          </Head>
          <CategoryContainer>
            {/* {
              selectedCategories.map((category,index)=>
                <Category key={index}>{category}</Category>  
              )
            } */}
            {
              categories.map(category=>{
                if(selectedCategories.includes(category.id)){
                  return(
                    <Category key={category.id}>{category.name}</Category> 
                  ) 
                }
              })
            }
          </CategoryContainer>
          {
            notesShow &&
            <NotesContainer>
              <Note>
                <img src={Alarm} alt="time" />
                {handleTime()}
              </Note>
              <Note>
                <img src={Cooking} alt="time" />
                {servings} People Servings
              </Note>
              <Note>
                <img src={Fire} alt="time" />
                {calory} Cal
              </Note>
            </NotesContainer>
          }
        </Right>
      </Top>
  )
}

export default Details

const Top = styled.div`
  display: flex;
  align-items: center;
  gap: 17px;
  padding-bottom: 32px;
  border-bottom: 1px solid ${colors.lightGray};
  margin-bottom: 32px;
`;
const Left = styled.div`
  width: 158px;
  height: 158px;
  position: relative;
  img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    &.edit{
      position: absolute;
      width: 50px;
      height: 50px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;
const Right = styled.div`
  width: 100%;
`;
const Head = styled.div`
  display: flex;
  gap: 12px;
  width: 50%;
  margin-bottom: 12px;
  @media all and (max-width: 1440px){
    width: 100%;
  }
`;
const Name = styled.h3`
  font-size: 28px;
  font-weight: 500;
  // width: 70%;
`;
const Type = styled.div`
  width: 36px;
  height: 36px;
  padding: 6px;
  img {
    width: 100%;
    height: 100%;
  }
`;
const CategoryContainer = styled.ul`
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
  margin-bottom: 12px;
`;
const Category = styled.li`
  min-height: 28px;
  border-radius: 78px;
  padding: 4px 12px;
  background: ${colors.borderLight};
  color: ${colors.darkGray};
`;
const NotesContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 32px;
  @media all and (max-width: 768px){
    gap: 20px;
  }
  @media all and (max-width: 630px){
    gap: 14px;
  }
`;
const Note = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
  img{
    width: 24px;
    height: 24px;
  }
`;

