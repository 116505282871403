export const colors = {
	primary: "#0FBA12",
	secondary: "#C4FACA",
	onSecondary: "#20652A",
	red: "#FF3232",
	black: "#222222",
	darkGray: "#A0A0A0",
	lightGray: "#E3E3E3",
	borderLight: "#F3F4F5",
	gray: "#FBFBFB",
	prime_white: "#FFFFFF",
	darkBlue: "#133149",
	lightGreen: '#EBFFED',
	borderGray: "#3A3A3A",

	//text color
	fontColorPrimary: "#222222",
	fontColorSecondary: "#A0A0A0",
	text_300: "#E3E3E3",
	text_200: "#F3F4F5",
	text_100: "#FBFBFB",
};
