import React from "react";
import styled from "styled-components";

import {
    FacebookShareButton,
    FacebookIcon,
    WhatsappShareButton,
    WhatsappIcon,
    EmailShareButton,
    EmailIcon,
    TwitterShareButton,
    TwitterIcon,
} from "react-share";

//constants
import { colors } from "../constants/Colors";

function SocialShare() {
    const fbUrl =
        "https://www.facebook.com/profile.php?id=100080188936804&mibextid=ZbWKwL";

    // const shareUrl = "support@guester.com";
    const shareUrl = window.location.href;

    // For gmail
    // Encode the subject for the URL
    const encodedSubject = encodeURIComponent("Guester");
    // Encode the body for the URL
    const encodedBody = encodeURIComponent(shareUrl);
    // Gmail compose URL with the pre-filled subject
    const gmailComposeUrl = `https://mail.google.com/mail/?view=cm&fs=1&tf=1&su=${encodedSubject}&body=${encodedBody}`;

    return (
        <MainContainer>
            <SocialMediaBox>
                <IconBox>
                    <FacebookShareButton url={shareUrl}>
                        <FacebookIcon size={50} />
                    </FacebookShareButton>
                </IconBox>
                <Name>Facebook</Name>
            </SocialMediaBox>
            <SocialMediaBox>
                <IconBox>
                    <EmailShareButton url={shareUrl}>
                        <EmailIcon 
                            size={50} 
                            onClick={(e) => {
                                window.open(gmailComposeUrl, '_blank');
                                e.preventDefault();
                            }}
                        />
                    </EmailShareButton>
                </IconBox>
                <Name>Gmail</Name>
            </SocialMediaBox>
            <SocialMediaBox>
                <IconBox>
                    <TwitterShareButton url={shareUrl}>
                        <TwitterIcon size={50} />
                    </TwitterShareButton>
                </IconBox>
                <Name>Twitter</Name>
            </SocialMediaBox>
            <SocialMediaBox>
                <IconBox>
                    <WhatsappShareButton url={shareUrl}>
                        <WhatsappIcon size={50} />
                    </WhatsappShareButton>
                </IconBox>
                <Name>Watsapp</Name>
            </SocialMediaBox>
        </MainContainer>
    );
}


const MainContainer = styled.div`
    background-color: ${colors.lightGray};
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
    padding: 15px 20px;
    border-radius: 8px;
    position: absolute;
    top: 40px;
    right: 0;
    @media all and (max-width: 360px) {
        top: 35px;
    }
`;
const SocialMediaBox = styled.div``;
const IconBox = styled.div`
    width: 50px;
    height: 50px;
    margin: 0 auto 5px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
`;
const Image = styled.img`
    display: block;
    width: 100%;
    object-fit: cover;
`;
const Name = styled.h4`
    font-size: 14px;
    font-family: "dm_sansregular";
    text-align: center;
`;
export default SocialShare;
